
import React from 'react';

import ProfilePane from './components/panes/ProfilePane';

function App() {
  return (
    <div>
      <ProfilePane />
    </div>
  );  
}

export default App;
