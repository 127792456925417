import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import "@fontsource/ubuntu-mono";
import Obfuscate from 'react-obfuscate';

interface IconLinkProps {
  href: string;
  icon: IconProp;
  text: string;
}

const IconLink: React.FC<IconLinkProps> = ({ href, icon, text }) => {
  return (
    // <a href={href} target="_blank" rel="noopener noreferrer">
    <Obfuscate href={href} obfuscateChildren={false}>
      <div className="text-blue-600 space-x-1 flex flex-row items-center">
        <FontAwesomeIcon icon={icon} size="lg" />
        <p>{text}</p>
      </div>
    </Obfuscate>
    // {/* </a> */}
  );
};

export default IconLink;