import React from 'react';
import { faMastodon, faGithub, faLinkedin, faSquareInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import "@fontsource/ubuntu-mono";
import avatar from '../../assets/avatar.jpg';

import IconLink from '../primitives/IconLink';

const ProfilePane: React.FC = () => {
    return (
        <div className="bg-indigo-300 h-screen flex justify-center items-center">
        <link rel="me" href="https://feed.bentley.sh/@allison" />
        {/* centered tile with rounded corners */}
            <div className="bg-white px-3 py-3 rounded-lg flex flex-row">
                {/* image on left */}
                <img src={avatar} alt="Allison Bentley" className="rounded-lg" style={{ width: '150px', height: '150px'}} />
                {/* text on right */}
                <div className="ml-2 flex flex-col">
                    <h1 className="text-xl font-bold">Allison Bentley</h1>
                    <IconLink href="mailto:allison@bentley.sh" icon={faEnvelope} text="Email" />

                    <IconLink href="https://feed.bentley.sh/@allison" icon={faMastodon} text="Mastodon" />          

                    <IconLink href="https://github.com/classic-ally" icon={faGithub} text="GitHub" />

                    <IconLink href="https://instagram.com/classically.ally" icon={faSquareInstagram} text="Instagram" />

                    <IconLink href="https://www.linkedin.com/in/allison-bentley/" icon={faLinkedin} text="LinkedIn" />
                </div>
            </div>
        </div>
    );
};

export default ProfilePane;